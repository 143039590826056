import "./src/styles/global.css"

// gatsby-browser.js
export const onClientEntry = () => {
	// Função para verificar o cookie zipdev-ignore
	const shouldLoadGTM = () => {
		if (typeof document !== 'undefined') {
			console.log('Verificando cookie...');
			const cookie = document.cookie.split('; ').find(row => row.startsWith('zipdev-ignore='));
			return !cookie; // Carrega o GTM se o cookie não existir
		}
		return false; // Não carrega o GTM se document não estiver definido
	};

	console.log('Resultado de shouldLoadGTM:', shouldLoadGTM());

	// Carrega o Google Tag Manager se necessário
	if (shouldLoadGTM() && typeof window !== 'undefined') {
		console.log('Carregando GTM...');
		(function(w, d, s, l, i) {
			w[l] = w[l] || [];
			w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
			var f = d.getElementsByTagName(s)[0],
				j = d.createElement(s),
				dl = l !== 'dataLayer' ? '&l=' + l : '';
			j.async = true;
			j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
			f.parentNode.insertBefore(j, f);
		})(window, document, 'script', 'dataLayer', 'GTM-MF8JR2RG'); // Substitua pelo seu ID GTM
	} else {
		console.log('GTM não foi carregado.');
	}
};
